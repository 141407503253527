import LanguageProvider from 'LanguageProvider/index';
import React from 'react';
import translation from './translation';
import ProductTemplate from 'components/productTemplate';
import { graphql, useStaticQuery } from 'gatsby';
import { ProductSpecificPageType } from 'components/productTemplate/types';

const ProductDocumentation = ({ pageUrl }: ProductSpecificPageType) => {
  const data = useStaticQuery(graphql`
    query {
      strapiProductSpecificPage(
        ONLY_FOR_DEV_pageId: { eq: "productDocument" }
      ) {
        HeroMainBannerDescription
        HeroMainBannerHeader
        HeroMainBannerCTAButtonLink
        HeroMainBannerCTAButtonLabel
        HeroMainBannerBackgroundImage {
          url
        }
        PageLabel
        PageIcon {
          url
        }
        HeroFooterBannerCTAHeader
        HeroFooterBannerCTADescription
        ProductOverviewHeader
        ProductOverviewSubHeader
        ProductOverviewImage {
          url
        }
        ProductBenefitHeader {
          name
        }
        ProductBenefitDescription {
          name
        }
        ProductModuleHeader
        ProductModuleDescription
        ProductModule {
          id
          Name
          SubHeader
          ModuleImage {
            url
          }
          ProductFeatureBenefit {
            id
            Header
            Description {
              name
            }
          }
        }
        FAQSectionalHeader
        FAQQuestionAndAnswer {
          header
          description
        }
        ViewMoreKeyFeaturesCTAButtonLabel
        KeyFeaturesSectionalHeader
        key_features {
          id
          label
          description
          image {
            url
          }
        }
        brochure {
          brochureName
          downloadLink
          dataLayer
          image {
            url
          }
        }
        seo {
          metaTitle
          metaDescription
        }
        ProductStructuredData {
          name
          description
          bestRating
          ratingValue
          reviewCount
        }
        related_articles {
          title
          publishedAt
          cover {
            alternativeText
            url
            formats {
              small {
                url
              }
            }
          }
          category {
            name
          }
          description
          slug
          readingTime {
            text
          }
        }
      }
    }
  `);
  return (
    <LanguageProvider messages={translation}>
      <ProductTemplate data={data} pageUrl={pageUrl} />
    </LanguageProvider>
  );
};

export default ProductDocumentation;
